#pending_accounts {
  .info_box {
    background-color: $theme-hue;
    border-radius: 10px;
    margin-top: 52px;
    color: rgba($color: #fff, $alpha: 0.8);

    p,
    h3 {
      line-height: 37px;
    }

    h3 {
      font-size: 17px;
      margin-bottom: 10px;
      color: $white;
    }
    /deep/.el-icon-check {
      width: 21px;
      height: 24px;
      background: #424242;
      border-radius: 50%;
      position: relative;
      top: 3px;
      margin-right: 15px;
    }
    /deep/.el-icon-check:before {
      content: '';
      background: url(~@/assets/uploads/check_icon.png) no-repeat;
      background-size: 16px;
      background-position: 5px;
      border-radius: 50%;
      width: 20px;
      height: 23px;
      display: inline-block;
    }
    li {
      line-height: 23px;
    }

    .icon {
      font-size: 20px;
      @include rtl-sass-prop(padding-right, padding-left, 5px);
    }

    p:nth-child(2) {
      margin-bottom: 20px;
    }

    .primary {
      margin-bottom: 40px;
      width: 50%;
      box-sizing: border-box;
      padding: 0 10px;
      @include screen-mobile() {
        width: 100%;
      }
    }

    .secondary {
      margin-bottom: 50px;
      width: 50%;
      box-sizing: border-box;
      padding: 0 10px;
      @include screen-mobile() {
        width: 100%;
      }
    }

    a {
      color: $white;
      text-decoration: none;
      border-bottom: 1px solid $yellow1;
      padding-bottom: 2px;
    }
  }

  .table {
    margin-top: 50px;
    .table_box {
      margin: 31px 0 0 0;
      /deep/ .el-table {
        tr {
          height: 75px;
        }
        tr {
          background: $theme-hue;
        }
      }
    }
  }
}
